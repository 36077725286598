// Authentication-related pages.
@import "variables";

.auth-page {
    width: 100%;
    min-height: 100vh;

    background: $cerulean-breeze;
    background: -webkit-linear-gradient(-135deg, $vivid-magenta, $cerulean-breeze);
    background: -o-linear-gradient(-135deg, $vivid-magenta, $cerulean-breeze);
    background: -moz-linear-gradient(-135deg, $vivid-magenta, $cerulean-breeze);
    background: linear-gradient(-135deg, $vivid-magenta, $cerulean-breeze);

    display: flex;
    align-items: center;
    justify-content: center;

    .auth-container {
        max-width: 450px;
        width: 100%;
        margin: 1em auto;

        border-radius: 3px;
        background-color: white;
        padding: 70px 50px 33px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        box-sizing: border-box;

        .auth-form {
            width: 100%;

            h1 {
                margin: 0 0 55px 0;
                font-size: 30px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 900;
            }

            .ant-input-prefix {
                width: 62px;
                margin-inline-end: 0;
            }

            .ant-form-item {
                .ant-input-affix-wrapper {
                    font-size: 18px;
                    padding: 0;
                }

                .ant-input-prefix {
                    width: 62px;
                    margin-inline-end: 0;

                    .anticon {
                        margin: 0 auto;
                    }
                }

                .ant-input-affix-wrapper-focused .ant-input-prefix .anticon {
                    color: $cerulean-breeze;
                }

                .ant-input {
                    height: 62px;
                    padding-left: 8px;
                }
            }
        }

        .ant-btn {
            margin-top: 25px;
            font-size: 16px;
            font-weight: bold;
            height: 62px;
            width: 100%;
            border-radius: 3px;
            background-color: $cerulean-breeze;
            text-transform: uppercase;
            text-decoration: none;
        }
    }
}
