.sider {
    :global {
        svg {
            height: 35px;
        }
    }
}

.siteLogo {
    padding: 16px 8px;
}
