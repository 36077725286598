@import "variables";

$blue: #005ffe;
$turquoise: #00e0ff;
$royal: #2127bd;
$red: #ff6c62;
$green: #28a745;


body {
    margin: 0;
    /*
    font-family: "Lato", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: #fafafa;
     */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global components */
.ant-card.circlebox {
    box-shadow: 0 20px 27px #0000000d;
    border-radius: 12px;
}

.ant-layout-content {
    min-height: calc(100vh - 64px);
}

.mb-24 {
    margin-bottom: 24px !important;
}

.m-0 {
    margin: 0;
}

.my-2rem {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-5rem {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.h-full, .h-100 {
    height: 100%;
}

.w-full, .w-100 {
    width: 100%;
}

.text-green, .text-success {
    color: $kelly-green;
}

.text-red, .text-danger {
    color: $pastel-red;
}

@import "auth";
