$moderate-violet: #9053c7;
$kelly-green: #52c41a;
$pastel-red: #ff6c62;
$philippine-gray: #8c8c8c;

// $muse-blue: #1890ff;
// $moderate-blue: #538ac7;

$cerulean-breeze: #4ea9ff;
$vivid-magenta: #fe1fcd;

$black: #000000e0;

$danger-red: #f32013;
